<template>
  <div class="hello">
    <div class="track">
      <h3>Connect.Соединять.Verbinden.Kumonekta</h3>
    </div>
    <div class="container position-absolute bottom-0 mb-5 start-50 translate-middle-x text-center text">
      <h2 class="h1 text-white fw-bold hero mb-4">Let's build your <em>project</em>.</h2>
      <router-link to="/contact/" class="btn btn-danger fw-bold py-3 px-5 pulse">Contact</router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer',
}
</script>